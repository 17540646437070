import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Text from 'components/deprecated/elements/Text';

import sizes from 'constants/sizes';
import paths from 'constants/paths';

class BlogCategoryLink extends Component {
  render() {
    return (
      <Link to={`${paths.BLOG}/${this.props.category.slug}`}>
        <Text size={this.props.size} isBold isUppercase color={this.props.category.textColor}>
          {this.props.category.title}
        </Text>
      </Link>
    );
  }
}

BlogCategoryLink.defaultProps = {
  size: sizes.SMALL,
};

BlogCategoryLink.propTypes = {
  size: PropTypes.oneOf(sizes.ALL),
  category: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
};

export default BlogCategoryLink;
