import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _partition from 'lodash/partition';
import formatDate from 'utils/date-utils';
import get from 'lodash/get';
import styled from 'styled-components/macro';
import { graphql } from 'gatsby';

import OldText from 'components/deprecated/elements/Text';
import { Title, Text, Image } from 'elements';
import BlogCategoryLink from 'staticPages/blog/BlogCategoryLink';
import Head from 'components/Head';
import BlogLanding from 'staticPages/blog/BlogLanding';
import BlogPosts from 'staticPages/blog/BlogPosts';
import { DiscoverMoreWrapper, Post } from 'staticPages/blog/Blog.styled';

import { parseRichText } from 'utils/contentful-utils';
import sectionTypes from 'constants/sectionTypes';
import paths from 'constants/paths';
import sizes from 'constants/sizes';
import { useBreakpoint, useStopLoading } from 'utils/hooks';

const PartnershipTitle = styled(OldText)`
  margin-top: 3em;
`;

const PartnersContainer = styled.div`
  display: flex;
  div {
    margin-right: 3em;
  }
`;

const BlogPost = (props) => {
  useEffect(() => {
    // Refresh affirm - handles async loaded affirm modal links
    if (window && window.affirm && window.affirm.ui && window.affirm.ui.refresh) window.affirm.ui.refresh();
  }, []);

  const size = useBreakpoint();
  const post = props.data?.contentfulBlogPost;
  const isLoading = !post;
  useStopLoading(!isLoading);

  if (isLoading) return null;

  const partitionPostsOfSameCat = () => {
    let [postsOfSameCat, restOfPosts] = _partition(props.data.allContentfulBlogPost.edges, (blogPost) => {
      return blogPost.node.postCategories?.find?.((cat) => cat.title === post.postCategories[0].title);
    });

    return [postsOfSameCat.filter((post) => post.node.contentful_id !== post.contentful_id), restOfPosts];
  };

  const fields = props.data.allContentfulLandingPage.edges[0];
  const discoverSection = fields.node.sections.find((section) => section.sys.contentType.sys.id === sectionTypes.TEXT);
  const [postsOfSameCat, restOfPosts] = partitionPostsOfSameCat();
  const discoverMorePosts = [...postsOfSameCat, ...restOfPosts];
  const ogImage = get(post, 'metaFogImage.fields.file.url') || get(post, 'mobileHeroImage.file.url');
  const twitterImage = get(post, 'metaTwitterImage.fields.file.url') || get(post, 'mobileHeroImage.file.url');
  const author = get(post, 'postAuthor.name');
  const partnershipCTAs =
    !_isEmpty(post.partnerships) &&
    post.partnerships.fields.percentages.map((partner) => {
      return (
        <Image
          key={partner.sys.id}
          absoluteLink={partner.fields?.callToAction?.fields?.linkUrl}
          className='lg-blog__partner-logo'
          picture={partner.fields?.mediaContent?.fields?.file?.url}
          alt={partner.fields?.mediaContent?.fields?.description}
        />
      );
    });

  const supportingCTAs =
    !_isEmpty(post.proudlySupporting) &&
    post.proudlySupporting.fields.percentages.map((org) => {
      return (
        <Image
          key={org.sys.id}
          className='lg-blog__partner-logo'
          picture={get(org.fields, 'mediaContent.fields.file.url')}
          alt={get(org.fields, 'mediaContent.fields.description')}
          absoluteLink={org.fields?.callToAction?.fields?.linkUrl}
        />
      );
    });
  return (
    <BlogLanding>
      <Post>
        <Head
          title={`Lettuce Grow Blog - ${get(post, 'title')}`}
          description={get(post, 'listingDescription')}
          ogImage={ogImage}
          twitterImage={twitterImage}
          author={author}
          date={get(post, 'postDate')}
          canonicalRoute={`${paths.BLOG}/${get(post, 'slug')}`}
        />
        <div className='lg-blog-post__inner'>
          <div className='lg-blog-post__heading'>
            <BlogCategoryLink size={sizes.SMALLISH} category={post.postCategories[0]} />
            <Title as='h1' modifiers='tertiaryLarge' content={post.title} />
            {!!author && <Text as='span' modifiers={['small', 'lightGrayColor', 'brandFont']} content={author} />}
            <Text as='span' content='&nbsp;|&nbsp;' modifiers={['small', 'lightGrayColor', 'brandFont']} />
            <Text as='span' modifiers={['small', 'lightGrayColor', 'brandFont']} content={formatDate(get(post, 'postDate'))} />
          </div>
          <div className='lg-blog-post__body'>
            {parseRichText(JSON.parse(post.body.raw), post.body.references)}
            <PartnersContainer>
              {!_isEmpty(post.partnerships) && (
                <div>
                  <PartnershipTitle isBold size={sizes.BASE}>
                    {post.partnerships.fields.title}
                  </PartnershipTitle>
                  {partnershipCTAs}
                </div>
              )}
              {!_isEmpty(post.proudlySupporting) && (
                <div>
                  <PartnershipTitle isBold size={sizes.BASE}>
                    {post.proudlySupporting.fields.title}
                  </PartnershipTitle>
                  {supportingCTAs}
                </div>
              )}
            </PartnersContainer>
          </div>
        </div>
        <DiscoverMoreWrapper>
          <Title modifiers='tertiaryLarge' content={discoverSection.title} />
          <BlogPosts
            customPageSize={3}
            customPosts={size === 'SM' || size === 'MD' ? [...discoverMorePosts.slice(3)] : discoverMorePosts}
          />
        </DiscoverMoreWrapper>
      </Post>
    </BlogLanding>
  );
};

export default BlogPost;

export const query = graphql`
  query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      ...BlogPostFragment
    }
    allContentfulBlogPost {
      edges {
        node {
          ...BlogPostFragment
        }
      }
    }
    allContentfulLandingPage(filter: { contentful_id: { eq: "6EIU5zv0YnxJXlYTrz3Lj6" } }) {
      edges {
        node {
          ...LandingPageFragment
        }
      }
    }
  }
`;
